import React, { useRef } from "react";
import { RequestStatus } from "types/common.types";
import useBodyIntersection from "hooks/useBodyIntersection";
import useNetworkConnectClientFetch from "./useNetworkConnectClientFetch";
import { composeConnectedClientsCountLabel } from "./utils";

import styles from "./styles.module.css";
import { Icon } from "@skyportal/ui-kit";

interface NetworkConnectedClientCountProps {
  networkId: string;
  show: boolean;
}

const NetworkConnectedClientCount: React.FC<NetworkConnectedClientCountProps> = ({ networkId, show }) => {
  const ref = useRef<HTMLSpanElement>(null);
  const isViewed = useBodyIntersection(ref);
  const { numberOfClients, requestStatus } = useNetworkConnectClientFetch(networkId, isViewed);

  return (
    <span ref={ref} hidden={!show}>
      {requestStatus === RequestStatus.PENDING ? (
        <Icon icon="circle" className={styles.loader} spin />
      ) : (
        composeConnectedClientsCountLabel(numberOfClients)
      )}
    </span>
  );
};

export default NetworkConnectedClientCount;
