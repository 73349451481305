import i18n from "i18next";
import { RootState } from "../index";
import { createSelector } from "reselect";

export const getNetworkSlice = (state: RootState) => state.networks;
export const getNetworkList = (state: RootState) => getNetworkSlice(state).networkList;
export const getNetworkFilter = (state: RootState) => getNetworkSlice(state).filter;
export const getNetworkListRequestStatus = (state: RootState) => getNetworkSlice(state).networkListRequest;
export const getSelectedNetworkId = (state: RootState) => getNetworkSlice(state).selectedNetworkId;

export const getOtherPageNetworkDropdownOptions = createSelector(getNetworkList, (networkList) =>
  networkList.map((item) => ({ value: item.id, label: item.name }))
);

export const getHomePageNetworkDropdownOptions = createSelector(getNetworkList, (networkList) => [
  { value: "_", label: i18n.t("All", "All", { ns: "naas" }) },
  ...networkList.map((item) => ({ value: item.id, label: item.name })),
]);

const emptyArr = [] as const;

export const getFilteredNetworkList = createSelector(getNetworkList, getNetworkFilter, (list, filter) => {
  if (!filter) {
    return list;
  }
  const filteredArr = list.filter((item) => item.name.toLowerCase().includes(filter.toLowerCase()));
  return filteredArr.length ? filteredArr : emptyArr;
});
