import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { contractSelectors } from "store/contracts";
import { networksSelectors, networksThunks } from "store/networks";
import { RequestStatus } from "../types/common.types";

const useCompanyNetworkListFetch = (): RequestStatus => {
  const dispatch = useDispatch();
  const selectedContractId = useSelector(contractSelectors.getSelectedContractId);

  useEffect(() => {
    const controller = new AbortController();
    if (selectedContractId) {
      dispatch(networksThunks.fetchCompanyNetworkList(selectedContractId, controller.signal));
    }

    return () => controller.abort();
  }, [dispatch, selectedContractId]);

  return useSelector(networksSelectors.getNetworkListRequestStatus);
};

export default useCompanyNetworkListFetch;
