import { BaseDeviceStatusSummary, CommonStatus, NetworkDevice } from "../../types/networks";

export const statusIconMap: Record<BaseDeviceStatusSummary["overallStatus"], string> = {
  warning: "info-attention",
  ok: "thumbs-up",
  critical: "thumbs-down",
};

export const statusToClassNameMap: Record<CommonStatus, string> = {
  warning: "--warning",
  ok: "--success",
  critical: "--danger",
};

export const typeIconMap: Record<NetworkDevice, string> = {
  uplink: "market",
  mx: "security-2",
  switches: "compare",
  wireless: "network-wifi",
  cameras: "camera",
};
