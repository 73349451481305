import { getUserProfileSkeleton } from "@skyportal/auth-web-client";
import { AppAction, AppStateSlice, SET_IS_AUTH_CHECKED, SET_USER_PROFILE } from "./app.types";

const initState: AppStateSlice = {
  userProfile: getUserProfileSkeleton(),
  isAuthChecked: false,
};

export default function AppReducer(state: AppStateSlice = initState, action: AppAction): AppStateSlice {
  switch (action.type) {
    case SET_USER_PROFILE: {
      return {
        ...state,
        userProfile: action.payload,
        isAuthChecked: true,
      };
    }
    case SET_IS_AUTH_CHECKED: {
      return {
        ...state,
        isAuthChecked: true,
      };
    }
    default:
      return state;
  }
}
