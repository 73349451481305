import { Dispatch, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";
import * as contractApi from "api/organizations";
import { networksSelectors } from "store/networks";
import { notificationErrorAC } from "store/notification/notification.actions";
import { ERROR_CANCELED_CODE, parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";
import { deviceListStoreInitState, deviceListStoreReducer } from "../deviceListStore/deviceListStore.reducer";
import { DeviceListAction, DeviceListStore } from "../deviceListStore/deviceListStore.types";

export const useDeviceListFetch = (): [DeviceListStore, Dispatch<DeviceListAction>] => {
  const reduxDispatch = useDispatch();
  const [store, dispatch] = useReducer(deviceListStoreReducer, deviceListStoreInitState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    const controller = new AbortController();
    if (organizationId && networkId) {
      dispatch({ type: "WIRELESS_DEVICES_REQUEST" });
      contractApi
        .getNetworkWirelessDeviceList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "WIRELESS_DEVICES_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "WIRELESS_DEVICES_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:wirelessDeviceListRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "SWITCHES_DEVICES_REQUEST" });
      contractApi
        .getNetworkSwitchesDeviceList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "SWITCHES_DEVICES_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "SWITCHES_DEVICES_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:switchesDeviceListRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_DATA_REQUEST" });
      contractApi
        .getNetworkFirewallData(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_DATA_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_DATA_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallDataRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL3InboundList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_L3_INBOUND_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRulesL3InboundRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL3OutboundList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_L3_OUTBOUND_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRulesL3OutboundRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_L7_REQUEST" });
      contractApi
        .getNetworkFirewallRulesL7List(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_L7_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_L7_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRulesL7RequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST" });
      contractApi
        .getNetworkFirewallRulesPortForwardingList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_PORT_FORWARDING_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRulesPortForwardingRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST" });
      contractApi
        .getNetworkFirewallRules11NATList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_1_1_NAT_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRules11NATRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });

      dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST" });
      contractApi
        .getNetworkFirewallRules1ManyNATList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "FIREWALL_RULES_1_MANY_NAT_REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:firewallRules1ManyNATRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });
    }

    return () => controller.abort();
  }, [organizationId, networkId, reduxDispatch]);

  return [store, dispatch];
};
