import { ApiNetworkListItemModel, ApiOrganizationModel } from "../api/organizations.types";
type ChekTypes = "string" | "number" | "boolean";

export const typeCheck = (data: unknown, prop: string, type: ChekTypes, modelName?: string): boolean => {
  if (!data || typeof data !== "object") {
    if (modelName) {
      console.error(`${modelName} INVALID: should be an object`, data);
    }

    return false;
  }
  if (typeof (data as Record<string, any>)[prop] !== type) {
    if (modelName) {
      console.error(`${modelName} INVALID: PROPERTY "${prop}" should has type "${type}"`, data);
    }

    return false;
  }

  return true;
};

export const organizationApiGuard = (data: unknown): data is ApiOrganizationModel => {
  const check = (prop: string, type: ChekTypes): boolean => typeCheck(data, prop, type, "CONTRACT");
  return check("id", "string") && check("name", "string");
};

export const networkApiGuard = (data: unknown): data is ApiNetworkListItemModel => {
  const check = (prop: string, type: ChekTypes): boolean => typeCheck(data, prop, type, "NETWORK");
  return check("id", "string") && check("name", "string");
};
