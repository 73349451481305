import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import config from "config";
import { appSelectors } from "store/app";
import { isInsideShell } from "../utils/env";

export const useNaasContractCheck = () => {
  const history = useHistory();
  const user = useSelector(appSelectors.getUserProfile);
  const isAuthChecked = useSelector(appSelectors.isAuthChecked);

  useEffect(() => {
    if (!config.redirectWithoutContract || !isAuthChecked) {
      return;
    }

    if ((user.contracts.NS ?? []).length === 0) {
      isInsideShell() ? history.replace("/") : window.location.assign(config.baseUrl);
    }
  }, [isAuthChecked, user, history]);
};
