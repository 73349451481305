import {
  CONTRACT_LIST_REQUEST,
  CONTRACT_LIST_REQUEST_FAILURE,
  CONTRACT_LIST_REQUEST_SUCCESS,
  ContractListRequestAC,
  ContractListRequestFailureAC,
  ContractListRequestSuccessAC,
  SELECT_CONTRACT_ID,
  SelectContractIdAC,
} from "./contracts.types";

export const contractListRequest: ContractListRequestAC = () => ({
  type: CONTRACT_LIST_REQUEST,
});

export const contractListRequestSuccess: ContractListRequestSuccessAC = (payload) => ({
  type: CONTRACT_LIST_REQUEST_SUCCESS,
  payload,
});

export const contractListRequestFailure: ContractListRequestFailureAC = () => ({
  type: CONTRACT_LIST_REQUEST_FAILURE,
});

export const selectContractIdAC: SelectContractIdAC = (payload) => ({
  type: SELECT_CONTRACT_ID,
  payload,
});
