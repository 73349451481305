import { combineReducers } from "redux";
import { reducer as app } from "./app";
import { reducer as contracts } from "./contracts";
import { reducer as networks } from "./networks";
import { reducer as notification } from "./notification";

const rootReducer = combineReducers({
  app,
  contracts,
  networks,
  notification,
});

export default rootReducer;
