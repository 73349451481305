import React, { useCallback, useEffect, useState } from "react";
import { Redirect, Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { useDeviceListFetch } from "./hooks/useDeviceListFetch";
import CardTabs from "components/CardTabs";
import { TabsConfigModel } from "components/CardTabs/CardTabs";
import WirelessDeviceList from "./tabs/WirelessDeviceList";
import SwitchesDeviceList from "./tabs/SwitchesDeviceList";
import FirewallDeviceInfo from "./tabs/Firewall/FirewallDeviceInfo";

export interface DeviceListProps {}

const tabsConfig: TabsConfigModel[] = [
  { name: "wirelessDevicesTab", path: "/naas/devices/wireless" },
  { name: "switchesDevicesTab", path: "/naas/devices/switches" },
  { name: "firewallDevicesTab", path: "/naas/devices/firewall" },
];

const DeviceList: React.FC<DeviceListProps> = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const [store, dispatch] = useDeviceListFetch();

  const [activeTab, setActiveTab] = useState<string>(
    tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name
  );

  useEffect(() => {
    setActiveTab(tabsConfig.find((tab) => tab.path === pathname)?.name || tabsConfig[0].name);
  }, [pathname]);

  const handleSortFieldChange = useCallback(
    (field) => dispatch({ type: "SET_DEVICES_LIST_SORT_FIELD", payload: field }),
    [dispatch]
  );
  const handleCurrentPageChange = useCallback(
    (value) => dispatch({ type: "SET_DEVICES_LIST_CURRENT_PAGE", payload: value }),
    [dispatch]
  );

  return (
    <>
      <CardTabs
        tabsConfig={tabsConfig}
        localeObjectName="deviceList"
        activeTab={activeTab}
        resetSortField={handleSortFieldChange}
        resetCurrentPage={handleCurrentPageChange}
      />
      <Switch>
        <Route path={`${path}/wireless`}>
          <WirelessDeviceList
            store={store}
            dispatch={dispatch}
            handleSortFieldChange={handleSortFieldChange}
            handleCurrentPageChange={handleCurrentPageChange}
          />
        </Route>
        <Route path={`${path}/switches`}>
          <SwitchesDeviceList
            store={store}
            dispatch={dispatch}
            handleSortFieldChange={handleSortFieldChange}
            handleCurrentPageChange={handleCurrentPageChange}
          />
        </Route>
        <Route path={`${path}/firewall`}>
          <FirewallDeviceInfo store={store} dispatch={dispatch} />
        </Route>
        <Route path="*">
          <Redirect to={`${path}/wireless`} />
        </Route>
      </Switch>
    </>
  );
};

export default React.memo(DeviceList);
