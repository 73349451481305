import { useDispatch, useSelector } from "react-redux";
import { contractActions, contractSelectors } from "../../store/contracts";
import { DropdownProps } from "@skyportal/ui-kit";
import { useCallback } from "react";
import { RequestStatus } from "types/common.types";
import { networksListRequestUncalledAC } from "store/networks/netwoks.actions";

interface UseContractDropdown {
  options: DropdownProps["options"];
  handleChange: DropdownProps["onChange"];
  value: string;
  requestStatus: RequestStatus;
}

const useContractDropdown = (): UseContractDropdown => {
  const dispatch = useDispatch();

  return {
    value: useSelector(contractSelectors.getSelectedContractId) ?? "",
    handleChange: useCallback(
      (value) => {
        dispatch(networksListRequestUncalledAC());
        dispatch(contractActions.selectContractIdAC(value));
      },
      [dispatch]
    ),
    options: useSelector(contractSelectors.getContractDropdownOptions),
    requestStatus: useSelector(contractSelectors.getContractListRequestStatus),
  };
};

export default useContractDropdown;
