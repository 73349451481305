import { RefObject, useEffect, useState } from "react";

const useBodyIntersection = (ref: RefObject<HTMLElement>): boolean => {
  const [isViewed, setIsViewed] = useState(!window.IntersectionObserver);

  useEffect(() => {
    if (ref.current && window.IntersectionObserver) {
      const observer = new IntersectionObserver((entries) => {
        const [current] = entries;

        if (current.target === ref.current && current.isIntersecting) {
          setIsViewed(() => true);
        }
      });

      observer.observe(ref.current);

      return () => observer.disconnect();
    }
  }, [ref]);

  return isViewed;
};

export default useBodyIntersection;
