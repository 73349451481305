import { RequestStatus } from "../../types/common.types";
import { ApiNetworkListItemModel } from "../../api/organizations.types";

export interface NetworkListItemModel extends ApiNetworkListItemModel {}
export type SelectedNetworkId = string | null;

export interface NetworksStateSlice {
  readonly networkListRequest: RequestStatus;
  readonly networkList: NetworkListItemModel[];
  readonly selectedNetworkId: SelectedNetworkId;
  readonly filter: string;
}

export const NETWORKS_LIST_REQUEST_UNCALLED = "NETWORKS/LIST_REQUEST_UNCALLED";
export type NetworksListRequestUncalledAction = { type: typeof NETWORKS_LIST_REQUEST_UNCALLED };
export type NetworksListRequestUncalledAC = () => NetworksListRequestUncalledAction;

export const NETWORKS_LIST_REQUEST = "NETWORKS/LIST_REQUEST";
export type NetworksListRequestActions = { type: typeof NETWORKS_LIST_REQUEST };
export type NetworksListRequestAC = () => NetworksListRequestActions;

export const SET_NETWORKS_FILTER = "NETWORKS/SET_FILTER";
export type SetNetworkFilterAction = {
  type: typeof SET_NETWORKS_FILTER;
  payload: string;
};
export type SetNetworkFilterAC = (payload: string) => SetNetworkFilterAction;

export const NETWORKS_LIST_REQUEST_SUCCESS = "NETWORKS/LIST_REQUEST_SUCCESS";
export type NetworksListRequestSuccessActions = {
  type: typeof NETWORKS_LIST_REQUEST_SUCCESS;
  payload: NetworkListItemModel[];
};
export type NetworksListRequestSuccessAC = (payload: NetworkListItemModel[]) => NetworksListRequestSuccessActions;

export const NETWORKS_LIST_REQUEST_FAILURE = "NETWORKS/LIST_REQUEST_FAILURE";
export type NetworksListRequestFailureActions = { type: typeof NETWORKS_LIST_REQUEST_FAILURE };
export type NetworksListRequestFailureAC = () => NetworksListRequestFailureActions;

export const SET_SELECTED_NETWORK_ID = "NETWORKS/SET_SELECTED_NETWORK_ID";
export type SetSelectedNetworkIdAction = { type: typeof SET_SELECTED_NETWORK_ID; payload: SelectedNetworkId };
export type SetSelectedNetworkIdAC = (payload: SelectedNetworkId) => SetSelectedNetworkIdAction;

export type NetworksActions =
  | SetNetworkFilterAction
  | SetSelectedNetworkIdAction
  | NetworksListRequestUncalledAction
  | NetworksListRequestActions
  | NetworksListRequestSuccessActions
  | NetworksListRequestFailureActions;
