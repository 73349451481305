import { ApiOrganizationModel } from "api/organizations.types";
import { RequestStatus } from "types/common.types";

export interface ContractModel extends ApiOrganizationModel {}

export type SelectedContractId = string | null;

export interface ContractsStateSlice {
  readonly contracts: ContractModel[];
  readonly contractListRequest: RequestStatus;
  readonly selectedContractId: SelectedContractId;
}

export const CONTRACT_LIST_REQUEST = "CONTRACTS/CONTRACT_LIST_REQUEST";
export type ContractListRequestAction = { type: typeof CONTRACT_LIST_REQUEST };
export type ContractListRequestAC = () => ContractListRequestAction;

export const CONTRACT_LIST_REQUEST_SUCCESS = "CONTRACTS/CONTRACT_LIST_REQUEST_SUCCESS";
export type ContractListRequestSuccessAction = { type: typeof CONTRACT_LIST_REQUEST_SUCCESS; payload: ContractModel[] };
export type ContractListRequestSuccessAC = (payload: ContractModel[]) => ContractListRequestSuccessAction;

export const CONTRACT_LIST_REQUEST_FAILURE = "CONTRACTS/CONTRACT_LIST_REQUEST_FAILURE";
export type ContractListRequestFailureAction = { type: typeof CONTRACT_LIST_REQUEST_FAILURE };
export type ContractListRequestFailureAC = () => ContractListRequestFailureAction;

export const SELECT_CONTRACT_ID = "CONTRACTS/SELECT_CONTRACT_ID";
export type SelectContractIdAction = { type: typeof SELECT_CONTRACT_ID; payload: SelectedContractId };
export type SelectContractIdAC = (payload: SelectedContractId) => SelectContractIdAction;

export type ContractActions =
  | SelectContractIdAction
  | ContractListRequestAction
  | ContractListRequestSuccessAction
  | ContractListRequestFailureAction;
