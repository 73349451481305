import { RequestStatus } from "types/common.types";
import { NetworkStatisticResponse } from "api/organizations.types";
import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";
import * as contractApi from "api/organizations";
import { networksSelectors } from "store/networks";
import useCustomEvent from "hooks/useCustomEvent";
import {
  PieChartDataItem,
  calculateUsageRxPercent,
  calculateUsageTxPercent,
  composeApplicationsChartData,
  composeDestinationsChartData,
} from "./utils";

interface Store {
  readonly requestStatus: RequestStatus;
  readonly percentRx: number;
  readonly percentTx: number;
  readonly applicationData: PieChartDataItem[];
  readonly destinationsData: PieChartDataItem[];
  readonly isDataEmpty: boolean;
  readonly message: string;
}

const initState: Store = {
  requestStatus: RequestStatus.UNCALLED,
  percentRx: 0,
  percentTx: 0,
  destinationsData: [],
  applicationData: [],
  message: "",
  isDataEmpty: false,
};

type RequestAction = { type: "REQUEST" };
type RequestSuccessAction = {
  type: "REQUEST_SUCCESS";
  payload: NetworkStatisticResponse;
};
type RequestFailureAction = { type: "REQUEST_FAILURE" };
type Action = RequestAction | RequestSuccessAction | RequestFailureAction;

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, requestStatus: RequestStatus.PENDING, isDataEmpty: false, message: "" };
    case "REQUEST_SUCCESS":
      const {
        usage = {
          txMB: 0,
          rxMB: 0,
        },
        applications = [],
        message,
      } = action.payload;
      return {
        ...state,
        requestStatus: RequestStatus.SUCCESS,
        percentRx: calculateUsageRxPercent(usage),
        percentTx: calculateUsageTxPercent(usage),
        applicationData: composeApplicationsChartData(applications),
        destinationsData: composeDestinationsChartData(applications),
        message: message ?? "",
        isDataEmpty: !usage.rxMB && !usage.txMB && !applications.length,
      };
    case "REQUEST_FAILURE":
      return { ...state, requestStatus: RequestStatus.FAILURE };
    default:
      return state;
  }
};

export const useNetworkStatisticFetch = (): Store => {
  const [store, dispatch] = useReducer(reducer, initState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  useCustomEvent("_refresh", () => {
    if (store.requestStatus !== RequestStatus.PENDING && organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkStatistics(organizationId, networkId, { invalidate: "1" })
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  });

  useEffect(() => {
    if (organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkStatistics(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  }, [organizationId, networkId]);

  return store;
};
