import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { networksActions, networksSelectors } from "store/networks";
import WirelessStatistics from "../WirelessStatistics";
import NetworkUtilizationStatistics from "../NetworkUtilizationStatistics";
import WirelessTrafficDistribution from "../WirelessTrafficDistribution";
import { RequestStatus } from "types/common.types";

export interface NetworkListProps {}

const NetworkDashboard: React.FC<NetworkListProps> = () => {
  const dispatch = useDispatch();
  const { networkList, networkListRequest } = useSelector(networksSelectors.getNetworkSlice);
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    if (networkListRequest === RequestStatus.SUCCESS && !selectedNetworkId) {
      dispatch(networksActions.setSelectedNetworkIdAC(networkList[0]?.id));
    }
  }, [dispatch, networkList, networkListRequest, selectedNetworkId]);

  return selectedNetworkId ? (
    <>
      <div data-testid="WirelessStatistics">
        <WirelessStatistics />
      </div>
      <div data-testid="NetworkUtilizationStatistics">
        <NetworkUtilizationStatistics />
      </div>
      {!!0 && (
        <div data-testid="WirelessTrafficDistribution">
          <WirelessTrafficDistribution />
        </div>
      )}
    </>
  ) : null;
};

export default NetworkDashboard;
