import { ContractActions, ContractsStateSlice } from "./contracts.types";
import { RequestStatus } from "types/common.types";
import { composeContractIdFromStateAndContractList } from "./utils";

const initState: ContractsStateSlice = {
  contractListRequest: RequestStatus.UNCALLED,
  contracts: [],
  selectedContractId: null,
};

export default function contractReducer(
  state: ContractsStateSlice = initState,
  action: ContractActions
): ContractsStateSlice {
  switch (action.type) {
    case "CONTRACTS/CONTRACT_LIST_REQUEST":
      return {
        ...state,
        contractListRequest: RequestStatus.PENDING,
      };
    case "CONTRACTS/CONTRACT_LIST_REQUEST_SUCCESS":
      return {
        ...state,
        contractListRequest: RequestStatus.SUCCESS,
        contracts: action.payload,
        selectedContractId: composeContractIdFromStateAndContractList(state, action.payload),
      };
    case "CONTRACTS/CONTRACT_LIST_REQUEST_FAILURE":
      return {
        ...state,
        contractListRequest: RequestStatus.FAILURE,
      };
    case "CONTRACTS/SELECT_CONTRACT_ID":
      return {
        ...state,
        selectedContractId: action.payload,
      };
    default:
      return state;
  }
}
