import React, { useCallback, useRef } from "react";
import Skeleton from "@material-ui/lab/Skeleton";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import useBodyIntersection from "hooks/useBodyIntersection";
import useDeviceStatusSummaryFetch from "containers/NetworkListItem/useDeviceStatusSummaryFetch";
import { networksActions } from "store/networks";
import { RequestStatus } from "types/common.types";
import NetworkStatusBox from "../NetworkStatusBox";

import styles from "./styles.module.css";

export interface NetworkDeviceSummaryProps {
  networkId: string;
  isNetworkSelected: boolean;
}

const NetworkDeviceSummary: React.FC<NetworkDeviceSummaryProps> = ({ networkId, isNetworkSelected }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const ref = useRef<HTMLDivElement>(null);
  const isViewed = useBodyIntersection(ref);
  const { statusSummary, requestStatus } = useDeviceStatusSummaryFetch(networkId, isViewed);
  const isPending = [RequestStatus.PENDING, RequestStatus.UNCALLED].includes(requestStatus);

  const handleFirewallStatusBoxClick = useCallback(() => {
    history.push("/naas/devices/firewall");
    if (!isNetworkSelected) dispatch(networksActions.setSelectedNetworkIdAC(networkId));
  }, [dispatch, history, isNetworkSelected, networkId]);

  const handleWirelessStatusBoxClick = useCallback(() => {
    history.push("/naas/devices/wireless");
    if (!isNetworkSelected) dispatch(networksActions.setSelectedNetworkIdAC(networkId));
  }, [dispatch, history, isNetworkSelected, networkId]);

  const handleSwitchesStatusBoxClick = useCallback(() => {
    history.push("/naas/devices/switches");
    if (!isNetworkSelected) dispatch(networksActions.setSelectedNetworkIdAC(networkId));
  }, [dispatch, history, isNetworkSelected, networkId]);

  return (
    <div ref={ref} className={styles.root}>
      {isPending &&
        Array(5)
          .fill(0)
          .map((_, key) => <Skeleton key={key} animation="wave" variant="rect" height={54} className={styles.box} />)}
      {!isPending && statusSummary?.uplink && <NetworkStatusBox type="uplink" source={statusSummary.uplink} />}
      {!isPending && statusSummary?.mx && (
        <NetworkStatusBox type="mx" source={statusSummary.mx} onClick={handleFirewallStatusBoxClick} />
      )}
      {!isPending && statusSummary?.switches && (
        <NetworkStatusBox type="switches" source={statusSummary.switches} onClick={handleSwitchesStatusBoxClick} />
      )}
      {!isPending && statusSummary?.wireless && (
        <NetworkStatusBox type="wireless" source={statusSummary.wireless} onClick={handleWirelessStatusBoxClick} />
      )}
      {!isPending && statusSummary?.cameras && <NetworkStatusBox type="cameras" source={statusSummary.cameras} />}
    </div>
  );
};

export default React.memo(NetworkDeviceSummary);
