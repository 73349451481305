import { Dispatch, useEffect, useReducer } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NetworkClientListResponse, NetworkClientModel } from "api/organizations.types";
import { RequestStatus } from "types/common.types";
import { contractSelectors } from "store/contracts";
import * as contractApi from "api/organizations";
import { networksSelectors } from "store/networks";
import { SortOrderType, getPaginationSlice, makeUniversalSort } from "utils/table";
import { notificationErrorAC } from "store/notification/notification.actions";
import { ERROR_CANCELED_CODE, convertKilobytes, parseApiErrorMessage } from "utils/helpers";
import i18n from "locales";

export type SortFieldType = keyof NetworkClientModel | "";

interface Store {
  readonly requestStatus: RequestStatus;
  readonly clients: NetworkClientModel[];
  readonly isDataEmpty: boolean;
  readonly filter: string;
  readonly sortField: SortFieldType;
  readonly sortOrder: SortOrderType;
  readonly currentPage: number;
  readonly perPage: number;
}

const initState: Store = {
  requestStatus: RequestStatus.UNCALLED,
  clients: [],
  isDataEmpty: false,
  filter: "",
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
  perPage: 10,
};

type RequestAction = { type: "REQUEST" };
type RequestSuccessAction = {
  type: "REQUEST_SUCCESS";
  payload: NetworkClientListResponse;
};
type RequestFailureAction = { type: "REQUEST_FAILURE" };
type SetClientListFilterAction = { type: "SET_CLIENT_LIST_FILTER"; payload: string };
type SetClientListSortFieldAction = { type: "SET_CLIENT_LIST_SORT_FIELD"; payload: SortFieldType };
type SetClientListPerPageAction = { type: "SET_CLIENT_LIST_PER_PAGE"; payload: number };
type SetClientListCurrentPageAction = { type: "SET_CLIENT_LIST_CURRENT_PAGE"; payload: number };
type Action =
  | RequestAction
  | RequestSuccessAction
  | RequestFailureAction
  | SetClientListFilterAction
  | SetClientListSortFieldAction
  | SetClientListPerPageAction
  | SetClientListCurrentPageAction;

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, requestStatus: RequestStatus.PENDING, isDataEmpty: false, currentPage: 1 };
    case "REQUEST_SUCCESS":
      const { clients } = action.payload;
      return {
        ...state,
        requestStatus: RequestStatus.SUCCESS,
        clients,
        isDataEmpty: !clients.length,
      };
    case "REQUEST_FAILURE":
      return { ...state, requestStatus: RequestStatus.FAILURE };
    case "SET_CLIENT_LIST_FILTER": {
      return { ...state, filter: action.payload };
    }
    case "SET_CLIENT_LIST_SORT_FIELD": {
      return {
        ...state,
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case "SET_CLIENT_LIST_PER_PAGE": {
      return {
        ...state,
        perPage: action.payload,
        currentPage: 1,
      };
    }
    case "SET_CLIENT_LIST_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }
    default:
      return state;
  }
};

export const useClientListFetch = (): [Store, Dispatch<Action>] => {
  const reduxDispatch = useDispatch();
  const [store, dispatch] = useReducer(reducer, initState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    const controller = new AbortController();
    if (organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkClientList(organizationId, networkId, controller.signal)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch((apiErrorMessage) => {
          if (apiErrorMessage.code !== ERROR_CANCELED_CODE) {
            dispatch({ type: "REQUEST_FAILURE" });
            reduxDispatch(
              notificationErrorAC({
                message: i18n.t("notification:clientListRequestFailure"),
                description: parseApiErrorMessage(apiErrorMessage),
              })
            );
          }
        });
    }

    return () => controller.abort();
  }, [organizationId, networkId, reduxDispatch]);

  return [store, dispatch];
};

const makeStatusSortNormalizer = (item: NetworkClientModel) => item.status + item.connectionType;
const makeDescriptionSortNormalizer = (item: NetworkClientModel) =>
  item.description ? item.description.toLowerCase() : "";
const makeLastSeenSortNormalizer = (item: NetworkClientModel) => item.lastSeen;
const makeUsageSortNormalizer = (item: NetworkClientModel) => item.usage.total;
const makeClientTypeSortNormalizer = (item: NetworkClientModel) => item.clientType;
const makeMacAddressSortNormalizer = (item: NetworkClientModel) => item.macAddress;
const makeIpv4SortNormalizer = (item: NetworkClientModel) => item.ipv4;
const makeIpv6SortNormalizer = (item: NetworkClientModel) => item.ipv6;
const makePortSortNormalizer = (item: NetworkClientModel) => item.port;
const makeConnectedToSortNormalizer = (item: NetworkClientModel) => item.connectedTo;

export const getClientListTable = ({ clients, filter, sortField, sortOrder, currentPage, perPage }: Store) => {
  let _list: NetworkClientModel[] = [...clients];

  const sortHandler: any = {
    status: makeUniversalSort(makeStatusSortNormalizer),
    description: makeUniversalSort(makeDescriptionSortNormalizer),
    lastSeen: makeUniversalSort(makeLastSeenSortNormalizer),
    usage: makeUniversalSort(makeUsageSortNormalizer),
    clientType: makeUniversalSort(makeClientTypeSortNormalizer),
    macAddress: makeUniversalSort(makeMacAddressSortNormalizer),
    ipv4: makeUniversalSort(makeIpv4SortNormalizer),
    ipv6: makeUniversalSort(makeIpv6SortNormalizer),
    port: makeUniversalSort(makePortSortNormalizer),
    connectedTo: makeUniversalSort(makeConnectedToSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  if (filter) {
    _list = _list.filter((item) => {
      const values = Object.values(item).map((value) => {
        if (typeof value === "string") {
          return value.toLowerCase();
        } else if (typeof value === "object" && !Array.isArray(value) && value !== null) {
          return `${convertKilobytes(value?.total)}, 
          ${convertKilobytes(value?.received)}, 
          ${convertKilobytes(value?.sent)}
          `.toLowerCase();
        }

        return "";
      });

      return values.some((value) => value.includes(filter.toLowerCase()));
    });
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};
