import { useMemo } from "react";
import { NetworkFirewallRules11NATAllowedInboundModel } from "api/organizations.types";
import { useTranslation } from "react-i18next";

const useFirewallRules11NATTableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "protocol",
        dataIndex: "protocol",
        title: t("deviceListTable.protocol"),
        sortDisabled: true,
      },
      {
        key: "ports",
        dataIndex: "ports",
        title: t("deviceListTable.ports"),
        render: (ports: NetworkFirewallRules11NATAllowedInboundModel["ports"]) => (ports ? ports.join(", ") : null),
        sortDisabled: true,
      },
      {
        key: "remoteIps",
        dataIndex: "remoteIps",
        title: t("deviceListTable.remoteIps"),
        render: (remoteIps: NetworkFirewallRules11NATAllowedInboundModel["remoteIps"]) =>
          remoteIps ? remoteIps.join(", ") : null,
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallRules11NATTableConfig;
