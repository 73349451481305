import React from "react";
import { Dropdown } from "@skyportal/ui-kit";
import useCompanyNetworkListFetch from "../../hooks/useCompanyNetworkListFetch";
import { RequestStatus } from "../../types/common.types";
import useNetworksDropdown from "./useNetworksDropdown";

export interface NetworksDropdownProps {
  className?: string;
}

const NetworksDropdown: React.FC<NetworksDropdownProps> = ({ className }) => {
  const requestStatus = useCompanyNetworkListFetch();
  const { options, value, handleChange } = useNetworksDropdown();

  return (
    <Dropdown
      prefixIcon="network-wifi-2"
      data-testid="networksDropdown"
      value={value}
      onChange={handleChange}
      options={options}
      disabled={[RequestStatus.PENDING, RequestStatus.UNCALLED].includes(requestStatus)}
      className={className}
    />
  );
};

export default NetworksDropdown;
