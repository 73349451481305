import React from "react";
import cx from "classnames";
import { Icon, Text, Tooltip } from "@skyportal/ui-kit";
import { useTranslation } from "react-i18next";
import { BaseDeviceStatusSummary, NetworkDevice } from "types/networks";
import { statusIconMap, statusToClassNameMap, typeIconMap } from "./mappings";

import styles from "./styles.module.css";

export interface NetworkStatusBoxProps {
  type: NetworkDevice;
  source: BaseDeviceStatusSummary;
  onClick?: () => void;
}

const NetworkStatusBox: React.FC<NetworkStatusBoxProps> = ({ type, source, onClick }) => {
  const { t } = useTranslation("naas");

  return source.total ? (
    <Tooltip title={t(`${source.overallStatus} status description`)}>
      <div
        className={cx(
          styles.container,
          styles[statusToClassNameMap[source.overallStatus]],
          onClick && styles.clickableNaaSStatusBox
        )}
        onClick={onClick}
        data-testid="NetworkStatusBox"
      >
        <Icon icon={statusIconMap[source.overallStatus]} className={styles.statusIcon} />
        <div className={styles.infoBox}>
          <div>{type === "mx" ? "firewall" : type}</div>
          <Text>
            {source.healthy}/{source.total}
          </Text>
        </div>
        <Icon icon={typeIconMap[type]} className={styles.bgIcon} />
      </div>
    </Tooltip>
  ) : null;
};

export default NetworkStatusBox;
