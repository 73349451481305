import {
  BaseDeviceStatusSummary,
  CommonStatus,
  NetworkApplicationStatistics,
  NetworkStatisticWirelessBand,
  NetworkStatisticWirelessSsid,
  NetworkUtilisationUsage,
  WirelessTrafficDistribution,
} from "../types/networks";

export interface ApiOrganizationModel {
  id: string;
  name: string;
}

export interface ApiNetworkListItemModel {
  id: string;
  name: string;
}

export interface OrganizationNetworkListResponse {
  networks?: ApiNetworkListItemModel[];
}

export interface NetworkConnectedClientCountResponse {
  numberOfClients: number;
}

export interface NetworkDevicesStatusSummaryResponse {
  uplink?: BaseDeviceStatusSummary & { primaryStatus: CommonStatus; spareStatus?: CommonStatus };
  mx?: BaseDeviceStatusSummary;
  switches?: BaseDeviceStatusSummary;
  wireless?: BaseDeviceStatusSummary;
  cameras?: BaseDeviceStatusSummary;
}

export interface NetworkStatisticWirelessResponse {
  ssids: NetworkStatisticWirelessSsid[];
  bands: NetworkStatisticWirelessBand[];
}

export interface NetworkStatisticResponse {
  usage: NetworkUtilisationUsage;
  applications: NetworkApplicationStatistics[];
  /**
   * @description Reason of empty response
   */
  message?: string;
}
export interface NetworkStatisticWirelessTrafficDistributionResponse {
  bands: WirelessTrafficDistribution[];
}

export interface RefreshQueryParam {
  invalidate?: "1";
}

export enum ClientStatus {
  Online = "Online",
  Offline = "Offline",
}
export enum ClientConnectionType {
  Wired = "Wired",
  Wireless = "Wireless",
}
export interface NetworkClientModel {
  id: string;
  status: ClientStatus;
  description: string;
  lastSeen: string;
  usage: {
    sent: number;
    received: number;
    total: number;
  };
  clientType: string;
  macAddress: string;
  ipv4: string;
  ipv6: string;
  port: string;
  connectedTo: string;
  connectionType: ClientConnectionType;
}

export interface NetworkClientListResponse {
  clients: NetworkClientModel[];
}

export enum DeviceStatus {
  Alerting = "Alerting",
  Dormant = "Dormant",
  Online = "Online",
  Offline = "Offline",
}

export interface NetworkWirelessDeviceModel {
  status: string;
  alerts: string;
  name: string;
  macAddress: string;
  serial: string;
  currentClients: number;
  powerSource: string;
}

export interface NetworkWirelessDeviceListResponse {
  devices: NetworkWirelessDeviceModel[];
}

export interface NetworkSwitchesDeviceModel {
  status: string;
  alerts: string;
  name: string;
  macAddress: string;
  serial: string;
  activePorts: string;
}

export interface NetworkSwitchesDeviceListResponse {
  devices: NetworkSwitchesDeviceModel[];
}

export interface NetworkFirewallModel {
  status: string;
  name: string;
  model: string;
  macAddress: string;
  serial: string;
  wan1Ip: string;
  wan2Ip: string;
}

export interface NetworkFirewallResponse {
  device: NetworkFirewallModel;
}

export enum FirewallRulePolicyStatus {
  Allow = "Allow",
  Deny = "Deny",
  Other = "Other",
}

export interface NetworkFirewallRulesL3Model {
  description: string;
  policy: FirewallRulePolicyStatus;
  protocol: string;
  sourcePort: string;
  source: string;
  destinationPort: string;
  destination: string;
}

export interface NetworkFirewallRulesL3Response {
  rules: NetworkFirewallRulesL3Model[];
}

export interface NetworkFirewallRulesL7Model {
  policy: string;
  type: string;
  application: string;
}

export interface NetworkFirewallRulesL7Response {
  rules: NetworkFirewallRulesL7Model[];
}

export interface NetworkFirewallRulesPortForwardingModel {
  lanIp: string;
  description: string;
  allowedRemoteIps: string[];
  publicPort: string;
  localPort: string;
  uplink: string;
  protocol: string;
}

export interface NetworkFirewallRulesPortForwardingResponse {
  rules: NetworkFirewallRulesPortForwardingModel[];
}

export interface NetworkFirewallRules11NATAllowedInboundModel {
  protocol: string;
  ports: string[];
  remoteIps: string[];
}

export interface NetworkFirewallRules11NATModel {
  name: string;
  publicIp: string;
  lanIp: string;
  uplink: string;
  allowedInbound: NetworkFirewallRules11NATAllowedInboundModel[];
}

export interface NetworkFirewallRules11NATResponse {
  rules: NetworkFirewallRules11NATModel[];
}

export interface NetworkFirewallRules1ManyNATPortRulesModel {
  description: string;
  protocol: string;
  publicPort: string;
  lanIp: string;
  localPort: string;
  remoteIps: string[];
}

export interface NetworkFirewallRules1ManyNATModel {
  publicIp: string;
  uplink: string;
  portRules: NetworkFirewallRules1ManyNATPortRulesModel[];
}

export interface NetworkFirewallRules1ManyNATResponse {
  rules: NetworkFirewallRules1ManyNATModel[];
}
