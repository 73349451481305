import { useMemo } from "react";
import { NetworkFirewallRules11NATAllowedInboundModel } from "api/organizations.types";
import { useTranslation } from "react-i18next";

const useFirewallRules1ManyNATTableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "description",
        dataIndex: "description",
        title: t("deviceListTable.description"),
        sortDisabled: true,
      },
      {
        key: "protocol",
        dataIndex: "protocol",
        title: t("deviceListTable.protocol"),
        sortDisabled: true,
      },
      {
        key: "publicPort",
        dataIndex: "publicPort",
        title: t("deviceListTable.publicPort"),
        sortDisabled: true,
      },
      {
        key: "lanIp",
        dataIndex: "lanIp",
        title: t("deviceListTable.lanIp"),
        sortDisabled: true,
      },
      {
        key: "localPort",
        dataIndex: "localPort",
        title: t("deviceListTable.localPort"),
        sortDisabled: true,
      },
      {
        key: "remoteIps",
        dataIndex: "remoteIps",
        title: t("deviceListTable.allowedRemoteIps"),
        render: (remoteIps: NetworkFirewallRules11NATAllowedInboundModel["remoteIps"]) =>
          remoteIps ? remoteIps.join(", ") : null,
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallRules1ManyNATTableConfig;
