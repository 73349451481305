import { useEffect, useRef } from "react";

type CustomEvent = "_refresh";

export function dispatchCustomEvent(event: CustomEvent, payload?: any) {
  document.dispatchEvent(new CustomEvent(event, { detail: payload }));
}
type CallBack = (event: Event) => void;

const useCustomEvent = (event: CustomEvent, cb: CallBack) => {
  const cbRef = useRef<CallBack>(cb);
  cbRef.current = cb;

  useEffect(() => {
    const handler = (event: Event) => cbRef.current(event);
    document.addEventListener(event, handler);
    return () => document.removeEventListener(event, handler);
  }, [event]);
};

export default useCustomEvent;
