const config = {
  redirectWithoutContract: (window as any).REDIRECT_WITHOUT_CONTRACT as string | undefined,
  widgetHost: String((window as any).WIDGET_HOST),
  baseUrl: String((window as any).BASE_URL),
  authWebUrl: String((window as any).AUTH_WEB_URL),
  authApiUrl: String((window as any).AUTH_API_URL),
  apiUrl: String((window as any).NETWORKS_API_URL),
} as const;

export default config;
