import { NetworksActions, NetworksStateSlice, SET_NETWORKS_FILTER } from "./netwoks.types";
import { SelectContractIdAction } from "../contracts/contracts.types";
import { RequestStatus } from "../../types/common.types";

export const initState: NetworksStateSlice = {
  networkList: [],
  networkListRequest: RequestStatus.UNCALLED,
  selectedNetworkId: null,
  filter: "",
};

export default function networksReducer(
  state: NetworksStateSlice = initState,
  action: NetworksActions | SelectContractIdAction
): NetworksStateSlice {
  switch (action.type) {
    case "NETWORKS/LIST_REQUEST_UNCALLED":
      return { ...state, networkListRequest: RequestStatus.UNCALLED };
    case "NETWORKS/LIST_REQUEST":
      return { ...state, networkListRequest: RequestStatus.PENDING };
    case "NETWORKS/LIST_REQUEST_SUCCESS":
      return { ...state, networkListRequest: RequestStatus.SUCCESS, networkList: action.payload };
    case "NETWORKS/LIST_REQUEST_FAILURE":
      return { ...state, networkListRequest: RequestStatus.FAILURE };
    case "NETWORKS/SET_SELECTED_NETWORK_ID":
      return { ...state, selectedNetworkId: action.payload === "_" ? null : action.payload };
    case "CONTRACTS/SELECT_CONTRACT_ID":
      return { ...state, networkList: [], selectedNetworkId: null, filter: "" };
    case SET_NETWORKS_FILTER: {
      return { ...state, filter: action.payload };
    }

    default:
      return state;
  }
}
