import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { networksSelectors } from "store/networks";
import { contractSelectors } from "store/contracts";
import { RequestStatus } from "types/common.types";
import * as contractApi from "api/organizations";
import { NetworkStatisticWirelessTrafficDistributionResponse } from "api/organizations.types";
import { WirelessTrafficDistribution } from "types/networks";

interface Store {
  readonly requestStatus: RequestStatus;
  readonly bands: WirelessTrafficDistribution[];
}

const initState: Store = {
  requestStatus: RequestStatus.UNCALLED,
  bands: [],
};

type RequestAction = { type: "REQUEST" };
type RequestSuccessAction = {
  type: "REQUEST_SUCCESS";
  payload: NetworkStatisticWirelessTrafficDistributionResponse;
};
type RequestFailureAction = { type: "REQUEST_FAILURE" };
type Action = RequestAction | RequestSuccessAction | RequestFailureAction;

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, requestStatus: RequestStatus.PENDING };
    case "REQUEST_SUCCESS":
      return { ...state, requestStatus: RequestStatus.SUCCESS, ...action.payload };
    case "REQUEST_FAILURE":
      return { ...state, requestStatus: RequestStatus.FAILURE };
    default:
      return state;
  }
};

export const useWirelessTrafficDistributionFetch = (): Store => {
  const [store, dispatch] = useReducer(reducer, initState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    if (organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkStatisticsWirelessTrafficDistribution(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  }, [organizationId, networkId]);

  return store;
};
