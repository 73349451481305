import { apiClient } from "./clients";
import {
  NetworkDevicesStatusSummaryResponse,
  NetworkStatisticResponse,
  NetworkStatisticWirelessResponse,
  NetworkStatisticWirelessTrafficDistributionResponse,
  OrganizationNetworkListResponse,
  NetworkConnectedClientCountResponse,
  RefreshQueryParam,
  NetworkClientListResponse,
  NetworkWirelessDeviceListResponse,
  NetworkSwitchesDeviceListResponse,
  NetworkFirewallResponse,
  NetworkFirewallRulesL3Response,
  NetworkFirewallRulesL7Response,
  NetworkFirewallRulesPortForwardingResponse,
  NetworkFirewallRules11NATResponse,
  NetworkFirewallRules1ManyNATResponse,
} from "./organizations.types";

export const getOrganizationNetworkList = (organizationId: string, signal?: AbortSignal) =>
  apiClient.request<OrganizationNetworkListResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks`,
    signal,
  });

export const getNetworkDevicesStatusSummary = (
  organizationId: string,
  networkId: string,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkDevicesStatusSummaryResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/status`,
    params,
  });

export const getNetworkConnectedClientCount = (
  organizationId: string,
  networkId: string,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkConnectedClientCountResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/clients`,
    params,
  });

export const getNetworkStatisticsWireless = (
  organizationId: string,
  networkId: string,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkStatisticWirelessResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/statistics/wireless`,
    params,
  });

export const getNetworkStatisticsWirelessTrafficDistribution = (organizationId: string, networkId: string) =>
  apiClient.request<NetworkStatisticWirelessTrafficDistributionResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/statistics/wireless/traffic-distribution`,
  });

export const getNetworkStatistics = (organizationId: string, networkId: string, params: RefreshQueryParam = {}) =>
  apiClient.request<NetworkStatisticResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/statistics`,
    params,
  });

export const getNetworkClientList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkClientListResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/client-list`,
    signal,
    params,
  });

export const getNetworkWirelessDeviceList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkWirelessDeviceListResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/wireless`,
    signal,
    params,
  });

export const getNetworkSwitchesDeviceList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkSwitchesDeviceListResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/switches`,
    signal,
    params,
  });

export const getNetworkFirewallData = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall`,
    signal,
    params,
  });

export const getNetworkFirewallRulesL3InboundList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRulesL3Response>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-l3-inbound`,
    signal,
    params,
  });

export const getNetworkFirewallRulesL3OutboundList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRulesL3Response>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-l3-outbound`,
    signal,
    params,
  });

export const getNetworkFirewallRulesL7List = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRulesL7Response>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-l7`,
    signal,
    params,
  });

export const getNetworkFirewallRulesPortForwardingList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRulesPortForwardingResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-port-forwarding`,
    signal,
    params,
  });

export const getNetworkFirewallRules11NATList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRules11NATResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-1-1-nat`,
    signal,
    params,
  });

export const getNetworkFirewallRules1ManyNATList = (
  organizationId: string,
  networkId: string,
  signal?: AbortSignal,
  params: RefreshQueryParam = {}
) =>
  apiClient.request<NetworkFirewallRules1ManyNATResponse>({
    method: "GET",
    url: `/v1/organizations/${organizationId}/networks/${networkId}/device-list/firewall/rules-1-many-nat`,
    signal,
    params,
  });
