interface AppLogger {
  error: (errorInput: string | Error, ctx?: string) => void;
}

const consoleHighlight = (msg: string) => {
  const line = "=".repeat(5);
  console.log(line, msg, line);
};

export default {
  error: (errorInput, ctx) => {
    consoleHighlight(ctx ?? "APP ERROR");
    console.error(errorInput);
  },
} as Readonly<AppLogger>;
