import { AppThunk } from "store";
import { authClient } from "api/clients";
import logger from "libs/logger";
import * as appActions from "./app.actions";
import * as contractActions from "../contracts/contracts.actions";
import { organizationApiGuard } from "utils/guards";
import { sortOrganizationByName } from "utils/sort";
import config from "config";

export const getUserInfo = (): AppThunk => async (dispatch) => {
  dispatch(contractActions.contractListRequest());
  try {
    const userProfile = await authClient.getUserProfile();
    const nsContracts = sortOrganizationByName(
      userProfile.permissions.entity
        .filter((contract) => contract.entityType === "NS")
        .map((contract) => ({
          id: contract.name,
          name: contract.organization,
        }))
        .filter(organizationApiGuard)
    );

    if (!nsContracts.length) {
      window.location.href = config.baseUrl;
    }

    dispatch(appActions.setUserProfile(userProfile));
    dispatch(contractActions.contractListRequestSuccess(nsContracts));
  } catch (error: any) {
    dispatch(appActions.setIsAuthCheckedAC());
    dispatch(contractActions.contractListRequestFailure());
    logger.error(error, "USER INFO REQUEST");
  }
};
