import { ContractModel, ContractsStateSlice, SelectedContractId } from "./contracts.types";

export const composeContractIdFromStateAndContractList = (
  state: ContractsStateSlice,
  contracts: ContractModel[]
): SelectedContractId => {
  if (typeof state.selectedContractId === "string" && contracts.find(({ id }) => id === state.selectedContractId)) {
    return state.selectedContractId;
  }

  return contracts[0]?.id ?? null;
};
