import React from "react";
import { DeviceListAction, DeviceListStore } from "containers/DeviceList/deviceListStore/deviceListStore.types";
import FirewallCard from "./FirewallCard";
import Layer3Card from "./Layer3Card";
import Layer7Card from "./Layer7Card";
import ForwardingRulesCard from "./ForwardingRulesCard";

export interface FirewallDeviceInfoProps {
  store: DeviceListStore;
  dispatch: React.Dispatch<DeviceListAction>;
}

const FirewallDeviceInfo: React.FC<FirewallDeviceInfoProps> = ({ store, dispatch }) => {
  const firewallData = store.firewall;

  return (
    <>
      <FirewallCard firewallData={firewallData} dispatch={dispatch} />
      <Layer3Card firewallData={firewallData} dispatch={dispatch} />
      <Layer7Card firewallData={firewallData} dispatch={dispatch} />
      <ForwardingRulesCard firewallData={firewallData} dispatch={dispatch} />
    </>
  );
};

export default React.memo(FirewallDeviceInfo);
