import { UserProfile } from "@skyportal/auth-web-client";

export interface AppStateSlice {
  readonly userProfile: UserProfile;
  readonly isAuthChecked: boolean;
}
export const SET_USER_PROFILE = "APP/SET_USER_PROFILE";
export type SetUserProfileAction = {
  type: typeof SET_USER_PROFILE;
  payload: UserProfile;
};
export type SetUserProfileAC = (userProfile: UserProfile) => SetUserProfileAction;

export const SET_IS_AUTH_CHECKED = "APP/SET_IS_AUTH_CHECKED";
export type SetIsAuthCheckedAction = { type: typeof SET_IS_AUTH_CHECKED };
export type SetIsAuthCheckedAC = () => SetIsAuthCheckedAction;

export type AppAction = SetUserProfileAction | SetIsAuthCheckedAction;
