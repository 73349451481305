import { SET_IS_AUTH_CHECKED, SET_USER_PROFILE, SetIsAuthCheckedAC, SetUserProfileAC } from "./app.types";

export const setUserProfile: SetUserProfileAC = (payload) => ({
  type: SET_USER_PROFILE,
  payload,
});

export const setIsAuthCheckedAC: SetIsAuthCheckedAC = () => ({
  type: SET_IS_AUTH_CHECKED,
});
