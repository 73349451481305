import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { contractSelectors } from "store/contracts";
import { getNetworkConnectedClientCount } from "api/organizations";
import useCustomEvent from "hooks/useCustomEvent";
import { RequestStatus } from "types/common.types";

const useNetworkConnectClientFetch = (networkId: string, isViewed: boolean) => {
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const [numberOfClients, setNumberOfClients] = useState<number>(0);
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.UNCALLED);

  useCustomEvent("_refresh", () => {
    if (requestStatus !== RequestStatus.PENDING && organizationId && networkId) {
      setRequestStatus(RequestStatus.PENDING);
      getNetworkConnectedClientCount(organizationId, networkId, { invalidate: "1" })
        .then(({ data: { numberOfClients } }) => {
          setRequestStatus(RequestStatus.SUCCESS);
          setNumberOfClients(numberOfClients);
        })
        .catch(() => setRequestStatus(RequestStatus.FAILURE));
    }
  });

  useEffect(() => {
    if (isViewed && organizationId && networkId) {
      setRequestStatus(RequestStatus.PENDING);
      getNetworkConnectedClientCount(organizationId, networkId)
        .then(({ data: { numberOfClients } }) => {
          setRequestStatus(RequestStatus.SUCCESS);
          setNumberOfClients(numberOfClients);
        })
        .catch(() => setRequestStatus(RequestStatus.FAILURE));
    }
  }, [organizationId, networkId, isViewed]);

  return { numberOfClients, requestStatus };
};

export default useNetworkConnectClientFetch;
