import { AppThunk } from "../index";
import * as contractsApi from "api/organizations";
import { networksListRequestAC, networksListRequestFailureAC, networksListRequestSuccessAC } from "./netwoks.actions";
import { networkApiGuard } from "utils/guards";
import { ERROR_CANCELED_CODE } from "utils/helpers";

export const fetchCompanyNetworkList =
  (organizationId: string, signal?: AbortSignal): AppThunk =>
  async (dispatch) => {
    dispatch(networksListRequestAC());
    try {
      const {
        data: { networks = [] },
      } = await contractsApi.getOrganizationNetworkList(organizationId, signal);
      dispatch(networksListRequestSuccessAC(networks.filter(networkApiGuard)));
    } catch (error: any) {
      if (error.code !== ERROR_CANCELED_CODE) {
        console.error(error);
        dispatch(networksListRequestFailureAC());
      }
    }
  };
