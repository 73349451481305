import React from "react";
import { Dropdown } from "@skyportal/ui-kit";
import { RequestStatus } from "../../types/common.types";
import useContractDropdown from "./useContractDropdown";

export interface ContractDropdownProps {
  className?: string;
}

const ContractDropdown: React.FC<ContractDropdownProps> = ({ className }) => {
  const { handleChange, options, value, requestStatus } = useContractDropdown();

  return (
    <Dropdown
      prefixIcon="user"
      data-testid="contractDropdown"
      onChange={handleChange}
      value={value}
      disabled={requestStatus === RequestStatus.PENDING}
      className={className}
      options={options}
    />
  );
};

export default React.memo(ContractDropdown);
