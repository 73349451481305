import {
  NetworkFirewallRules11NATAllowedInboundModel,
  NetworkFirewallRules1ManyNATPortRulesModel,
  NetworkFirewallRulesL3Model,
  NetworkFirewallRulesL7Model,
  NetworkFirewallRulesPortForwardingModel,
  NetworkSwitchesDeviceModel,
  NetworkWirelessDeviceModel,
} from "api/organizations.types";
import {
  DeviceListStore,
  NetworkFirewal1ManyNATRulesStoreModel,
  NetworkFirewall11NATRulesStoreModel,
} from "./deviceListStore.types";
import { getPaginationSlice, makeUniversalSort } from "utils/table";

const makeStatusSortNormalizer = (item: NetworkWirelessDeviceModel) => item.status;
const makeAlertsSortNormalizer = (item: NetworkWirelessDeviceModel) => item.alerts;
const makeNameSortNormalizer = (item: NetworkWirelessDeviceModel) => item.name;
const makeMacAddressSortNormalizer = (item: NetworkWirelessDeviceModel) => item.macAddress;
const makeSerialSortNormalizer = (item: NetworkWirelessDeviceModel) => item.serial;
const makeCurrentClientsSortNormalizer = (item: NetworkWirelessDeviceModel) => item.currentClients;
const makePowerSourceSortNormalizer = (item: NetworkWirelessDeviceModel) => item.powerSource;

export const getWirelessDeviceListTable = ({
  wireless: { list, filter, perPage },
  sortField,
  sortOrder,
  currentPage,
}: DeviceListStore) => {
  let _list: NetworkWirelessDeviceModel[] = [...list];

  const sortHandler: any = {
    status: makeUniversalSort(makeStatusSortNormalizer),
    alerts: makeUniversalSort(makeAlertsSortNormalizer),
    name: makeUniversalSort(makeNameSortNormalizer),
    macAddress: makeUniversalSort(makeMacAddressSortNormalizer),
    serial: makeUniversalSort(makeSerialSortNormalizer),
    currentClients: makeUniversalSort(makeCurrentClientsSortNormalizer),
    powerSource: makeUniversalSort(makePowerSourceSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  if (filter) {
    _list = _list.filter((item) => {
      const values = Object.values(item).map((value) => {
        if (typeof value === "string") {
          return value.toLowerCase();
        } else if (typeof value === "number") {
          return String(value);
        }

        return "";
      });

      return values.some((value) => value.includes(filter.toLowerCase()));
    });
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

const makeActivePortsSortNormalizer = (item: NetworkSwitchesDeviceModel) =>
  typeof item.activePorts === "string" ? Number(item.activePorts.split("/")[0]) : 0;

export const getSwitchesDeviceListTable = ({
  switches: { list, filter, perPage },
  sortField,
  sortOrder,
  currentPage,
}: DeviceListStore) => {
  let _list: NetworkSwitchesDeviceModel[] = [...list];

  const sortHandler: any = {
    status: makeUniversalSort(makeStatusSortNormalizer),
    alerts: makeUniversalSort(makeAlertsSortNormalizer),
    name: makeUniversalSort(makeNameSortNormalizer),
    macAddress: makeUniversalSort(makeMacAddressSortNormalizer),
    serial: makeUniversalSort(makeSerialSortNormalizer),
    activePorts: makeUniversalSort(makeActivePortsSortNormalizer),
  };

  if (sortField && sortHandler[sortField]) {
    _list.sort(sortHandler[sortField](sortOrder));
  }

  if (filter) {
    _list = _list.filter((item) => {
      const values = Object.values(item).map((value) => {
        if (typeof value === "string") {
          return value.toLowerCase();
        } else if (typeof value === "number") {
          return String(value);
        }

        return "";
      });

      return values.some((value) => value.includes(filter.toLowerCase()));
    });
  }

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRulesL3InboundTable = ({
  rulesL3Inbound: { list, perPage, currentPage },
}: DeviceListStore["firewall"]) => {
  let _list: NetworkFirewallRulesL3Model[] = [...list];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRulesL3OutboundTable = ({
  rulesL3Outbound: { list, perPage, currentPage },
}: DeviceListStore["firewall"]) => {
  let _list: NetworkFirewallRulesL3Model[] = [...list];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRulesL7Table = ({ rulesL7: { list, perPage, currentPage } }: DeviceListStore["firewall"]) => {
  let _list: NetworkFirewallRulesL7Model[] = [...list];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRulesPortForwardingTable = ({
  rulesPortForwarding: { list, perPage, currentPage },
}: DeviceListStore["firewall"]) => {
  let _list: NetworkFirewallRulesPortForwardingModel[] = [...list];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRules11NATTable = ({
  allowedInbound,
  perPage,
  currentPage,
}: NetworkFirewall11NATRulesStoreModel) => {
  let _list: NetworkFirewallRules11NATAllowedInboundModel[] = [...allowedInbound];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};

export const getFirewallRules1ManyNATTable = ({
  portRules,
  perPage,
  currentPage,
}: NetworkFirewal1ManyNATRulesStoreModel) => {
  let _list: NetworkFirewallRules1ManyNATPortRulesModel[] = [...portRules];

  const total = _list.length;

  return {
    total,
    pageCount: Math.ceil(total / perPage),
    list: getPaginationSlice(_list, currentPage, perPage),
  };
};
