import i18n from "locales";
import { NetworkApplicationStatistics, NetworkUtilisationUsage } from "types/networks";
import { COLORS } from "utils/charts";

export function networkPieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.4; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
    </div>`;
}

const normalizeNumber = (input: number): number => Number(input.toFixed(2));

export const calculateUsageRxPercent = (usage: NetworkUtilisationUsage | null): number =>
  normalizeNumber(usage ? (usage.rxMB * 100) / (usage.rxMB + usage.txMB) : 0);

export const calculateUsageTxPercent = (usage: NetworkUtilisationUsage | null): number =>
  normalizeNumber(usage ? (usage.txMB * 100) / (usage.rxMB + usage.txMB) : 0);

export interface PieChartDataItem {
  name: string;
  value: number;
}

export const composeChartData = (
  target: "destination" | "application",
  data: NetworkApplicationStatistics[]
): PieChartDataItem[] => {
  const applicationMap = data.reduce((acc, item) => {
    if (!acc[item[target]]) {
      acc[item[target]] = 0;
    }
    acc[item[target]] += item.rxMB + item.txMB;
    return acc;
  }, {} as Record<string, number>);

  const result = Object.keys(applicationMap)
    .map((name) => ({ name: name === "null" ? i18n.t("Unknown") : name, value: applicationMap[name] }))
    .sort((a, b) => (a.value >= b.value ? -1 : 1));

  if (result.length > 10) {
    const top10 = result.slice(0, 10);

    return [
      ...top10,
      { name: i18n.t("Other"), value: normalizeNumber(result.slice(10).reduce((acc, item) => acc + item.value, 0)) },
    ];
  }

  return result;
};

export const composeApplicationsChartData = (data: NetworkApplicationStatistics[]) =>
  composeChartData("application", data);

export const composeDestinationsChartData = (data: NetworkApplicationStatistics[]) =>
  composeChartData("destination", data);

export const networkChartTooltipFormatter = (item: PieChartDataItem[][0]): { name: string; value: string | number } => {
  return { name: item.name, value: item.value.toLocaleString() + "MB" };
};

export const composeRxTxChartOptions = (percentRx: number, percentTx: number) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacing: [24, 16, 8, 16],
      plotShadow: false,
      type: "column",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    colors: ["#44d9fc", "#e59a7f"],
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "<b>{series.name}</b><br>Distribution: <b>{point.y}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    xAxis: {
      categories: [""],
      labels: { enabled: false },
    },
    yAxis: {
      labels: { format: "{text}%" },
      title: "",
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: "Rx",
        data: [percentRx],
      },
      {
        name: "Tx",
        data: [percentTx],
      },
    ],
    legend: {
      itemDistance: 16,
      alignColumns: true,
      useHTML: true,
    },
  };
};

export const composeNetworkDataChartOptions = (data: PieChartDataItem[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "pie",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    colors: COLORS,
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "<b>{point.y} MB</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: data.map((item) => ({
          name: item.name,
          y: Number(item.value.toFixed(2)),
        })),
      },
    ],
    legend: {
      itemDistance: 16,
      alignColumns: true,
      useHTML: true,
      labelFormatter: networkPieChartLegendLabelFormatter,
      align: "right",
      verticalAlign: "middle",
      itemStyle: {
        width: 120,
        textOverflow: null,
      },
      navigation: {
        arrowSize: 10,
        activeColor: "#000",
        inactiveColor: "#ccc",
        style: {
          fontWeight: "bold",
        },
      },
    },
  };
};
