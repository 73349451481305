import config from "config";
import createAuthClient from "@skyportal/auth-web-client";
import axios from "axios";

export const authClient = createAuthClient({
  authApiURL: config.authApiUrl,
  authWebURL: config.authWebUrl,
});

export const apiClient = axios.create({
  baseURL: config.apiUrl,
  withCredentials: true,
});
