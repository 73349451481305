import {
  NETWORKS_LIST_REQUEST,
  NETWORKS_LIST_REQUEST_FAILURE,
  NETWORKS_LIST_REQUEST_SUCCESS,
  NETWORKS_LIST_REQUEST_UNCALLED,
  NetworksListRequestAC,
  NetworksListRequestFailureAC,
  NetworksListRequestSuccessAC,
  NetworksListRequestUncalledAC,
  SET_NETWORKS_FILTER,
  SET_SELECTED_NETWORK_ID,
  SetNetworkFilterAC,
  SetSelectedNetworkIdAC,
} from "./netwoks.types";

export const networksListRequestUncalledAC: NetworksListRequestUncalledAC = () => ({
  type: NETWORKS_LIST_REQUEST_UNCALLED,
});

export const networksListRequestAC: NetworksListRequestAC = () => ({
  type: NETWORKS_LIST_REQUEST,
});

export const networksListRequestFailureAC: NetworksListRequestFailureAC = () => ({
  type: NETWORKS_LIST_REQUEST_FAILURE,
});

export const networksListRequestSuccessAC: NetworksListRequestSuccessAC = (payload) => ({
  type: NETWORKS_LIST_REQUEST_SUCCESS,
  payload,
});

export const setSelectedNetworkIdAC: SetSelectedNetworkIdAC = (payload) => ({
  type: SET_SELECTED_NETWORK_ID,
  payload,
});

export const setNetworkFilterAC: SetNetworkFilterAC = (payload) => ({
  type: SET_NETWORKS_FILTER,
  payload,
});
