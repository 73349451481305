export const COLORS = [
  "#c48df7",
  "#e59a7f",
  "#44d9fc",
  "#b4b4b4",
  "#2e2256",
  "#1f603f",
  "#19495e",
  "#cafbe2",
  "#c5f5fa",
  "#f3e8fd",
  "#ffd0d0",
];
