import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { NetworkStatisticWirelessResponse } from "api/organizations.types";
import { RequestStatus } from "types/common.types";
import { contractSelectors } from "store/contracts";
import * as contractApi from "api/organizations";
import { networksSelectors } from "store/networks";
import useCustomEvent from "hooks/useCustomEvent";
import { NetworkStatisticWirelessBand, NetworkStatisticWirelessSsid } from "types/networks";

interface Store {
  readonly requestStatus: RequestStatus;
  readonly ssids: NetworkStatisticWirelessSsid[];
  readonly bands: NetworkStatisticWirelessBand[];
  readonly isDataEmpty: boolean;
}

const initState: Store = {
  requestStatus: RequestStatus.UNCALLED,
  ssids: [],
  bands: [],
  isDataEmpty: false,
};

type RequestAction = { type: "REQUEST" };
type RequestSuccessAction = {
  type: "REQUEST_SUCCESS";
  payload: NetworkStatisticWirelessResponse;
};
type RequestFailureAction = { type: "REQUEST_FAILURE" };
type Action = RequestAction | RequestSuccessAction | RequestFailureAction;

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, requestStatus: RequestStatus.PENDING, isDataEmpty: false };
    case "REQUEST_SUCCESS":
      const { ssids, bands } = action.payload;
      return {
        ...state,
        requestStatus: RequestStatus.SUCCESS,
        ssids: action.payload.ssids,
        bands: action.payload.bands,
        isDataEmpty: !ssids.length && !bands.length,
      };
    case "REQUEST_FAILURE":
      return { ...state, requestStatus: RequestStatus.FAILURE };
    default:
      return state;
  }
};

export const useWirelessStatisticFetch = (): Store => {
  const [store, dispatch] = useReducer(reducer, initState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);
  const networkId = useSelector(networksSelectors.getSelectedNetworkId);

  useCustomEvent("_refresh", () => {
    if (store.requestStatus !== RequestStatus.PENDING && organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkStatisticsWireless(organizationId, networkId, { invalidate: "1" })
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  });

  useEffect(() => {
    if (organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      contractApi
        .getNetworkStatisticsWireless(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  }, [organizationId, networkId]);

  return store;
};
