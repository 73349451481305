import React from "react";
import { useTranslation } from "react-i18next";
import { TextInput } from "@skyportal/ui-kit";
import styles from "./styles.module.css";
import { useSelector } from "react-redux";
import { networksActions, networksSelectors } from "store/networks";
import { useAppDispatch } from "../../store";

interface FilterNetworkListBarProps {}

const FilterNetworkListBar: React.FC<FilterNetworkListBarProps> = () => {
  const { t } = useTranslation("naas");
  const value = useSelector(networksSelectors.getNetworkFilter);
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);
  const dispatch = useAppDispatch();

  if (selectedNetworkId) {
    return null;
  }

  return (
    <div className={styles.filterInputBlock} data-testid="FilterNetworkListBar">
      <TextInput
        autoFocus
        name="network_filter"
        value={value}
        onChange={(event) => dispatch(networksActions.setNetworkFilterAC(event.currentTarget.value))}
        className={styles.filterInput}
        placeholder={t("Filter networks by name")}
      />
    </div>
  );
};

export default React.memo(FilterNetworkListBar);
