import { RequestStatus } from "types/common.types";
import { DeviceListAction, DeviceListStore } from "./deviceListStore.types";

export const deviceListStoreInitState: DeviceListStore = {
  wireless: {
    requestStatus: RequestStatus.UNCALLED,
    list: [],
    isDataEmpty: false,
    filter: "",
    perPage: 10,
  },
  switches: {
    requestStatus: RequestStatus.UNCALLED,
    list: [],
    isDataEmpty: false,
    filter: "",
    perPage: 10,
  },
  firewall: {
    requestStatus: RequestStatus.UNCALLED,
    device: {},
    rulesL3Inbound: { list: [], requestStatus: RequestStatus.UNCALLED, currentPage: 1, perPage: 5 },
    rulesL3Outbound: { list: [], requestStatus: RequestStatus.UNCALLED, currentPage: 1, perPage: 5 },
    rulesL7: { list: [], requestStatus: RequestStatus.UNCALLED, currentPage: 1, perPage: 5 },
    rulesPortForwarding: { list: [], requestStatus: RequestStatus.UNCALLED, currentPage: 1, perPage: 5 },
    rules11NAT: { list: [], requestStatus: RequestStatus.UNCALLED },
    rules1ManyNAT: { list: [], requestStatus: RequestStatus.UNCALLED },
  },
  sortField: "",
  sortOrder: "desc",
  currentPage: 1,
};

export const deviceListStoreReducer = (state: DeviceListStore, action: DeviceListAction): DeviceListStore => {
  switch (action.type) {
    case "WIRELESS_DEVICES_REQUEST":
      return {
        ...state,
        wireless: { ...state.wireless, requestStatus: RequestStatus.PENDING, isDataEmpty: false },
        currentPage: 1,
      };
    case "WIRELESS_DEVICES_REQUEST_SUCCESS":
      return {
        ...state,
        wireless: {
          ...state.wireless,
          requestStatus: RequestStatus.SUCCESS,
          list: action.payload.devices,
          isDataEmpty: !action.payload.devices.length,
        },
      };
    case "WIRELESS_DEVICES_REQUEST_FAILURE":
      return { ...state, wireless: { ...state.wireless, requestStatus: RequestStatus.FAILURE } };
    case "SET_WIRELESS_DEVICES_LIST_FILTER": {
      return { ...state, wireless: { ...state.wireless, filter: action.payload } };
    }
    case "SET_WIRELESS_DEVICES_LIST_PER_PAGE": {
      return {
        ...state,
        wireless: {
          ...state.wireless,
          perPage: action.payload,
        },
        currentPage: 1,
      };
    }

    case "SWITCHES_DEVICES_REQUEST":
      return {
        ...state,
        switches: { ...state.switches, requestStatus: RequestStatus.PENDING, isDataEmpty: false },
        currentPage: 1,
      };
    case "SWITCHES_DEVICES_REQUEST_SUCCESS":
      return {
        ...state,
        switches: {
          ...state.switches,
          requestStatus: RequestStatus.SUCCESS,
          list: action.payload.devices,
          isDataEmpty: !action.payload.devices.length,
        },
      };
    case "SWITCHES_DEVICES_REQUEST_FAILURE":
      return { ...state, switches: { ...state.switches, requestStatus: RequestStatus.FAILURE } };
    case "SET_SWITCHES_DEVICES_LIST_FILTER": {
      return { ...state, switches: { ...state.switches, filter: action.payload } };
    }
    case "SET_SWITCHES_DEVICES_LIST_PER_PAGE": {
      return {
        ...state,
        switches: {
          ...state.switches,
          perPage: action.payload,
        },
        currentPage: 1,
      };
    }
    case "SET_DEVICES_LIST_SORT_FIELD": {
      return {
        ...state,
        switches: {
          ...state.switches,
        },
        sortField: state.sortField === action.payload && state.sortOrder === "asc" ? "" : action.payload,
        sortOrder: state.sortField === action.payload && state.sortOrder === "desc" ? "asc" : "desc",
        currentPage: 1,
      };
    }
    case "SET_DEVICES_LIST_CURRENT_PAGE": {
      return {
        ...state,
        currentPage: action.payload,
      };
    }

    case "FIREWALL_DATA_REQUEST":
      return {
        ...state,
        firewall: { ...state.firewall, requestStatus: RequestStatus.PENDING },
      };
    case "FIREWALL_DATA_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          requestStatus: RequestStatus.SUCCESS,
          device: action.payload.device,
        },
      };
    case "FIREWALL_DATA_REQUEST_FAILURE":
      return { ...state, firewall: { ...state.firewall, requestStatus: RequestStatus.FAILURE } };

    case "FIREWALL_RULES_L3_INBOUND_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Inbound: {
            ...state.firewall.rulesL3Inbound,
            requestStatus: RequestStatus.PENDING,
            currentPage: 1,
          },
        },
      };
    case "FIREWALL_RULES_L3_INBOUND_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Inbound: {
            ...state.firewall.rulesL3Inbound,
            list: action.payload.rules,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_L3_INBOUND_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Inbound: {
            ...state.firewall.rulesL3Inbound,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_L3_INBOUND_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Inbound: {
            ...state.firewall.rulesL3Inbound,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_L3_INBOUND_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Inbound: {
            ...state.firewall.rulesL3Inbound,
            currentPage: action.payload,
          },
        },
      };
    }

    case "FIREWALL_RULES_L3_OUTBOUND_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Outbound: {
            ...state.firewall.rulesL3Outbound,
            requestStatus: RequestStatus.PENDING,
            currentPage: 1,
          },
        },
      };
    case "FIREWALL_RULES_L3_OUTBOUND_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Outbound: {
            ...state.firewall.rulesL3Outbound,
            list: action.payload.rules,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_L3_OUTBOUND_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Outbound: {
            ...state.firewall.rulesL3Outbound,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_L3_OUTBOUND_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Outbound: {
            ...state.firewall.rulesL3Outbound,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_L3_OUTBOUND_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL3Outbound: {
            ...state.firewall.rulesL3Outbound,
            currentPage: action.payload,
          },
        },
      };
    }

    case "FIREWALL_RULES_L7_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL7: {
            ...state.firewall.rulesL7,
            requestStatus: RequestStatus.PENDING,
            currentPage: 1,
          },
        },
      };
    case "FIREWALL_RULES_L7_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL7: {
            ...state.firewall.rulesL7,
            list: action.payload.rules,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_L7_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL7: {
            ...state.firewall.rulesL7,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_L7_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL7: {
            ...state.firewall.rulesL7,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_L7_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesL7: {
            ...state.firewall.rulesL7,
            currentPage: action.payload,
          },
        },
      };
    }

    case "FIREWALL_RULES_PORT_FORWARDING_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesPortForwarding: {
            ...state.firewall.rulesPortForwarding,
            requestStatus: RequestStatus.PENDING,
            currentPage: 1,
          },
        },
      };
    case "FIREWALL_RULES_PORT_FORWARDING_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesPortForwarding: {
            ...state.firewall.rulesPortForwarding,
            list: action.payload.rules,
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_PORT_FORWARDING_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesPortForwarding: {
            ...state.firewall.rulesPortForwarding,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_PORT_FORWARDING_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesPortForwarding: {
            ...state.firewall.rulesPortForwarding,
            perPage: action.payload,
            currentPage: 1,
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_PORT_FORWARDING_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rulesPortForwarding: {
            ...state.firewall.rulesPortForwarding,
            currentPage: action.payload,
          },
        },
      };
    }

    case "FIREWALL_RULES_1_1_NAT_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules11NAT: {
            ...state.firewall.rules11NAT,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    case "FIREWALL_RULES_1_1_NAT_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules11NAT: {
            ...state.firewall.rules11NAT,
            list: action.payload.rules.map((rule) => ({ ...rule, perPage: 5, currentPage: 1 })),
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_1_1_NAT_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules11NAT: {
            ...state.firewall.rules11NAT,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_1_1_NAT_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules11NAT: {
            ...state.firewall.rules11NAT,
            list: state.firewall.rules11NAT.list.map((rule, index) => {
              if (index === action.payload.ruleIndex) {
                rule.perPage = action.payload.perPage;
                rule.currentPage = 1;
              }
              return rule;
            }),
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_1_1_NAT_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules11NAT: {
            ...state.firewall.rules11NAT,
            list: state.firewall.rules11NAT.list.map((rule, index) => {
              if (index === action.payload.ruleIndex) {
                rule.currentPage = action.payload.currentPage;
              }
              return rule;
            }),
          },
        },
      };
    }

    case "FIREWALL_RULES_1_MANY_NAT_REQUEST":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules1ManyNAT: {
            ...state.firewall.rules1ManyNAT,
            requestStatus: RequestStatus.PENDING,
          },
        },
      };
    case "FIREWALL_RULES_1_MANY_NAT_REQUEST_SUCCESS":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules1ManyNAT: {
            ...state.firewall.rules1ManyNAT,
            list: action.payload.rules.map((rule) => ({ ...rule, perPage: 5, currentPage: 1 })),
            requestStatus: RequestStatus.SUCCESS,
          },
        },
      };
    case "FIREWALL_RULES_1_MANY_NAT_REQUEST_FAILURE":
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules1ManyNAT: {
            ...state.firewall.rules1ManyNAT,
            requestStatus: RequestStatus.FAILURE,
          },
        },
      };
    case "SET_FIREWALL_RULES_1_MANY_NAT_PER_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules1ManyNAT: {
            ...state.firewall.rules1ManyNAT,
            list: state.firewall.rules1ManyNAT.list.map((rule, index) => {
              if (index === action.payload.ruleIndex) {
                rule.perPage = action.payload.perPage;
              }
              return rule;
            }),
          },
        },
      };
    }
    case "SET_FIREWALL_RULES_1_MANY_NAT_CURRENT_PAGE": {
      return {
        ...state,
        firewall: {
          ...state.firewall,
          rules1ManyNAT: {
            ...state.firewall.rules1ManyNAT,
            list: state.firewall.rules1ManyNAT.list.map((rule, index) => {
              if (index === action.payload.ruleIndex) {
                rule.currentPage = action.payload.currentPage;
              }
              return rule;
            }),
          },
        },
      };
    }

    default:
      return state;
  }
};
