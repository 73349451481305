import { NetworkStatisticWirelessBand, NetworkStatisticWirelessSsid } from "types/networks";
import { COLORS } from "utils/charts";

export function wirelessPieChartLegendLabelFormatter(this: {
  itemDistance: number;
  alignColumns: boolean;
  useHTML: boolean;
  labelFormatters: () => string;
}) {
  return `
    <div style="font-weight: normal; font-size: 14px; line-height: 1.4; font-family: Dustin Sans; margin-bottom: 4px; ">
      <div>${(this as any).name}</div>
      <div style="color: #8d8d8d; font-size: 12px; line-height: 1.2;">${(this as any).y} ${
    (this as any).y === 1 ? "client" : "clients"
  }</div>
    </div>`;
}

export const composeBySsidChartOptions = (ssids: NetworkStatisticWirelessSsid[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "pie",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    colors: COLORS,
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: ssids
          .filter((ssid) => !ssid.name.startsWith("Unconfigured SSID"))
          .map((item) => ({
            name: item.name,
            y: item.connectedClients,
          })),
      },
    ],
    legend: {
      itemDistance: 16,
      alignColumns: true,
      useHTML: true,
      labelFormatter: wirelessPieChartLegendLabelFormatter,
      align: "right",
      verticalAlign: "middle",
      navigation: {
        arrowSize: 10,
        activeColor: "#000",
        inactiveColor: "#ccc",
        style: {
          fontWeight: "bold",
        },
      },
    },
  };
};

export const composeByBandChartOptions = (bands: NetworkStatisticWirelessBand[]) => {
  return {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      borderRadius: 20,
      spacingBottom: 0,
      plotShadow: false,
      type: "pie",
      height: 350,
      style: {
        fontFamily: "Dustin Sans",
      },
    },
    colors: COLORS,
    credits: {
      enabled: false,
    },
    title: {
      text: null,
    },
    tooltip: {
      pointFormat: "Quantity: <b>{point.y}</b><br> Distribution: <b>{point.percentage:.1f}%</b>",
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data: bands.map((item) => ({
          name: `${item.band} Ghz`,
          y: item.connectedClients,
        })),
      },
    ],
    legend: {
      itemDistance: 16,
      alignColumns: true,
      useHTML: true,
      labelFormatter: wirelessPieChartLegendLabelFormatter,
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      navigation: {
        arrowSize: 10,
        activeColor: "#000",
        inactiveColor: "#ccc",
        style: {
          fontWeight: "bold",
        },
      },
    },
  };
};
