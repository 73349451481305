import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PageLayout from "layouts/PageLayout";
import PageHeader from "components/PageHeader";
import DeviceList from "containers/DeviceList";
import { networksActions, networksSelectors } from "store/networks";
import { RequestStatus } from "types/common.types";
import { Card } from "@skyportal/ui-kit";
import InfoPlaceholder from "components/InfoPlaceholder";

export const DeviceListPage: React.FC = () => {
  const { t } = useTranslation("naas");
  const dispatch = useDispatch();
  const history = useHistory();
  const { networkListRequest, networkList } = useSelector(networksSelectors.getNetworkSlice);
  const selectedNetworkId = useSelector(networksSelectors.getSelectedNetworkId);

  useEffect(() => {
    if (selectedNetworkId === null && networkListRequest === RequestStatus.SUCCESS) {
      networkList.length && dispatch(networksActions.setSelectedNetworkIdAC(networkList[0].id));
    }
  }, [dispatch, history, networkList, networkListRequest, selectedNetworkId]);

  return (
    <PageLayout>
      <PageHeader path="/naas/devices" />
      {selectedNetworkId === null && networkListRequest === RequestStatus.SUCCESS && !networkList.length ? (
        <Card>
          <InfoPlaceholder title={t("No networks found")} />
        </Card>
      ) : (
        <DeviceList />
      )}
    </PageLayout>
  );
};

export default DeviceListPage;
