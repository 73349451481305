import { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
import { RequestStatus } from "types/common.types";
import { NetworkDevicesStatusSummaryResponse } from "api/organizations.types";
import { getNetworkDevicesStatusSummary } from "api/organizations";
import { contractSelectors } from "store/contracts";
import useCustomEvent from "hooks/useCustomEvent";

interface StatusSummary extends NetworkDevicesStatusSummaryResponse {}

interface Store {
  readonly requestStatus: RequestStatus;
  readonly statusSummary: StatusSummary | null;
}

const initState: Store = {
  requestStatus: RequestStatus.UNCALLED,
  statusSummary: null,
};

type RequestAction = { type: "REQUEST" };
type RequestSuccessAction = { type: "REQUEST_SUCCESS"; payload: StatusSummary };
type RequestFailureAction = { type: "REQUEST_FAILURE" };
type Action = RequestAction | RequestSuccessAction | RequestFailureAction;

const reducer = (state: Store, action: Action): Store => {
  switch (action.type) {
    case "REQUEST":
      return { ...state, requestStatus: RequestStatus.PENDING };
    case "REQUEST_SUCCESS":
      return { ...state, requestStatus: RequestStatus.SUCCESS, statusSummary: action.payload };
    case "REQUEST_FAILURE":
      return { ...state, requestStatus: RequestStatus.FAILURE };
    default:
      return state;
  }
};

const useDeviceStatusSummaryFetch = (networkId: string, isViewed: boolean): Store => {
  const [store, dispatch] = useReducer(reducer, initState);
  const organizationId = useSelector(contractSelectors.getSelectedContractId);

  useCustomEvent("_refresh", () => {
    if (store.requestStatus !== RequestStatus.PENDING && organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      getNetworkDevicesStatusSummary(organizationId, networkId, { invalidate: "1" })
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  });

  useEffect(() => {
    if (isViewed && organizationId && networkId) {
      dispatch({ type: "REQUEST" });

      getNetworkDevicesStatusSummary(organizationId, networkId)
        .then(({ data }) => dispatch({ type: "REQUEST_SUCCESS", payload: data }))
        .catch(() => dispatch({ type: "REQUEST_FAILURE" }));
    }
  }, [organizationId, networkId, isViewed]);

  return store;
};

export default useDeviceStatusSummaryFetch;
