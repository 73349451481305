import { useMemo } from "react";
import { NetworkFirewallRulesPortForwardingModel } from "api/organizations.types";
import { useTranslation } from "react-i18next";

const useFirewallRulesPortForwardingTableConfig = () => {
  const { t } = useTranslation("deviceList");

  const columnsConfig = useMemo(
    () => [
      {
        key: "description",
        dataIndex: "description",
        title: t("deviceListTable.description"),
        sortDisabled: true,
      },
      {
        key: "uplink",
        dataIndex: "uplink",
        title: t("deviceListTable.uplink"),
        sortDisabled: true,
      },
      {
        key: "protocol",
        dataIndex: "protocol",
        title: t("deviceListTable.protocol"),
        sortDisabled: true,
      },
      {
        key: "publicPort",
        dataIndex: "publicPort",
        title: t("deviceListTable.publicPort"),
        sortDisabled: true,
      },
      {
        key: "lanIp",
        dataIndex: "lanIp",
        title: t("deviceListTable.lanIp"),
        sortDisabled: true,
      },
      {
        key: "localPort",
        dataIndex: "localPort",
        title: t("deviceListTable.localPort"),
        sortDisabled: true,
      },
      {
        key: "allowedRemoteIps",
        dataIndex: "allowedRemoteIps",
        title: t("deviceListTable.allowedRemoteIps"),
        render: (allowedRemoteIps: NetworkFirewallRulesPortForwardingModel["allowedRemoteIps"]) =>
          allowedRemoteIps ? allowedRemoteIps.join(", ") : null,
        sortDisabled: true,
      },
    ],
    [t]
  );

  return columnsConfig;
};

export default useFirewallRulesPortForwardingTableConfig;
