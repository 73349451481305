export const ERROR_CANCELED_CODE = "ERR_CANCELED";

export const parseApiErrorMessage = (error: any): string | null => {
  if (!error || !error.isAxiosError) {
    return null;
  }

  try {
    const { msg: message } = error.response?.data;
    return message || null;
  } catch (e) {
    return null;
  }
};

export const convertKilobytes = (kilobytes: string | number): string => {
  if (typeof kilobytes === "string") {
    kilobytes = Number(kilobytes);
  }
  if (isNaN(Number(kilobytes)) || kilobytes === null || kilobytes === undefined) {
    return "";
  }

  const units = ["KB", "MB", "GB", "TB"];
  let i = 0;
  while (kilobytes >= 1024 && i < units.length - 1) {
    kilobytes /= 1024;
    i++;
  }
  return `${kilobytes.toFixed(2)} ${units[i]}`;
};
